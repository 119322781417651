<template>
  <div class="catalogDetail">
    <heade></heade>
    <div
      class="cen_wrap"
      :style="{
        backgroundImage: 'url(' + backgroud + ')',
      }"
    >
      <div class="catalogDetail_cen nice-scroll">
        <div v-for="(item, index) in booksByCid" :key="index">
          <a :href="item.tb_link" target="_blank">
            <img :src="item.copy" alt="" />
            <div class="cen-name">{{ item.name }}</div>
          </a>
          <div class="contents" v-html="item.content"></div>
        </div>
      </div>
      <div
        class="link_box"
        :class="
          queryWhere.id == 1 || queryWhere.id == 6 ? 'link_box2' : 'link_box'
        "
      >
        <div class="link">
          <router-link to="ProjectIntroduction">课题研究介绍</router-link>
        </div>
        <div class="link" v-for="(item, index) in bookCategorys" :key="index">
          <router-link
            :to="{
              path: pathUrl,
              query: { id: item.id },
            }"
            :class="queryWhere.id == item.id ? 'add' : ''"
            >{{ item.name }}</router-link
          >
        </div>
        <div class="link">
          <router-link to="AuxiliaryReading1"
            >人教版全国中小学传统文化教学一体机</router-link
          >
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { getbooksByCid, getbookCategorys } from "@api/public";

export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      backgroud: "",
      pathUrl: "",
      bookCategorys: [],
      booksByCid: [],
      url: [],
      content: "",
    };
  },
  mounted: function () {
    let that = this;
    getbooksByCid(that.queryWhere).then(function (res) {
      that.$set(that, "booksByCid", res.data);
    });
    getbookCategorys().then((res) => {
      that.$set(that, "bookCategorys", res.data);
      that.bookCategorys.forEach(function (element) {
        if (element.id == that.queryWhere.id) {
          that.backgroud = element.background_url;
        }
      });
    });
    scroll($(".nice-scroll"));
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.pathUrl = this.$route.meta.path;
    document.title = this.$route.meta.title;
  },
  methods: {},
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.catalogDetail .cen_wrap {
  width: 100%;
  height: 2384px;
  background-size: cover;
  background-position: center 0;
}
.catalogDetail .catalogDetail_cen {
  width: 1310px;
  height: 925px;
  position: relative;
  top: 620px;
  padding: 0 45px;
  box-sizing: border-box;
  margin: 0 auto;
}
.catalogDetail_cen::-webkit-scrollbar {
  width: 0 !important;
}
.catalogDetail .link_box {
  width: 800px;
  height: 250px;
  position: absolute;
  top: 1830px;
  bottom: 100px;
  left: 476px;
  display: flex;
  align-items: center;
}
.catalogDetail .cen_wrap .link_box2 {
  width: 800px;
  height: 250px;
  position: absolute;
  top: 1790px;
  bottom: 100px;
  left: 476px;
  display: flex;
  align-items: center;
}
.catalogDetail .link {
  height: 220px;
  writing-mode: vertical-rl;
  padding-right: 60px;
}

.catalogDetail .link > a {
  color: #006649;
  font-size: 18px;
  font-family: kaiti;
  font-weight: 600;
  line-height: 26px;
}

.catalogDetail_cen > div {
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.catalogDetail_cen > div + div {
  margin-top: 15px;
}

.catalogDetail_cen > div > a {
  width: 200px !important;
  height: 200px !important;
  max-width: auto !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  margin-right: 20px;
}

.catalogDetail_cen > div > a > img {
  width: 200px !important;
  height: 200px !important;
  max-width: auto !important;
  margin-right: 20px;
}
.cen-name {
  text-align: center;
}
.catalogDetail_cen > div {
  display: flex;
}
.contents {
  flex-grow: 1;
}
.link > a:hover {
  color: #900c0c;
}
.catalogDetail .link > .add {
  color: #900c0c;
}
</style>